<template>
  <div id="services">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Header />

      <Alert
        :showAlert="isServiceUpdated"
        type="success"
        allowDismiss
        absolute
        text="The changes have been saved successfully"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
    <v-container
      fluid
      class="d-flex flex-column px-2"
    >
      <AdsButton
        width="220"
        class="mb-2"
        :primary="true"
        buttonText="Add Subcategory"
        icon="mdi-plus"
        :disabled="isReadOnlyUser"
        @click="createNewService"
      />
      <AdsDataTable
        :headers="headers"
        :items="items"
        :footer-props="{
          'items-per-page-options': [20, 30, 50, -1],
        }"
        :items-per-page="20"
        search-label="Find Subcategory"
        sort-desc
        @click:row="openService"
      />
    </v-container>
  </div>
</template>
<script>
import { AdsDataTable, AdsButton, Alert } from '@nswdoe/doe-ui-core'
import Header from '@/views/Services/Header/Header'

export default {
  name: 'ServicesListing',
  title: 'Subcategories - SAIS Operation UI Portal',
  components: {
    AdsDataTable,
    AdsButton,
    Header,
    Alert,
  },
  computed: {
    isServiceUpdated() {
      return this.$route.query.created === 'true' || this.$route.query.updated === 'true'
    },
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
  },
  data() {
    return {
      headers: [
        { text: 'Subcategory ID', value: 'id' },
        { text: 'Description', value: 'description' },
      ],
      items: [],
      itemsPerPageOptions: [
        { value: 20, title: '20' },
        { value: 30, title: '30' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
        { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
      ],
    }
  },
  created() {
    this.$store.dispatch('moduleServices/fetchServices', {}).finally(() => {
      this.items = this.$store.state.moduleServices.services
    })
  },

  methods: {
    openService(item) {
      this.$router.push(`/serviceregistry/services/view/${item.id}`)
    },
    createNewService() {
      this.$router.push('/serviceregistry/services/add')
    },
  },
}
</script>
